import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 950.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

  
<path d="M4305 7475 c-161 -27 -470 -69 -630 -86 -60 -6 -111 -13 -113 -14 -2
-2 -8 -239 -13 -527 -5 -287 -15 -676 -24 -863 -8 -187 -20 -457 -25 -600 -6
-143 -17 -366 -25 -495 -39 -623 -55 -869 -60 -910 -3 -25 -12 -133 -21 -240
-8 -107 -21 -247 -28 -310 -7 -63 -11 -117 -10 -119 2 -2 43 2 91 9 49 6 149
20 223 30 74 10 196 28 270 39 74 11 236 34 360 51 124 16 250 35 280 40 30 5
114 17 185 25 72 9 155 20 185 25 76 13 106 17 170 25 30 3 118 15 195 25 193
26 575 76 720 95 66 8 123 19 127 23 7 7 15 101 46 530 4 56 4 102 0 102 -4 0
-51 -9 -105 -19 -124 -25 -316 -58 -413 -71 -41 -5 -120 -17 -175 -25 -55 -9
-167 -25 -250 -35 -82 -11 -159 -22 -171 -25 -27 -6 -371 -51 -539 -70 -71 -9
-154 -18 -182 -22 l-53 -6 0 44 c0 79 20 692 24 774 12 195 62 1164 71 1370
15 335 36 729 50 920 26 367 26 335 1 334 -11 0 -84 -11 -161 -24z"/>
<path d="M4730 7450 c-4 -8 -11 -93 -15 -187 -5 -141 -4 -173 7 -173 63 0 267
-25 373 -46 151 -29 358 -86 465 -127 41 -16 92 -35 113 -43 36 -14 234 -110
306 -150 55 -30 279 -180 322 -216 218 -179 287 -244 411 -388 49 -58 100
-117 113 -132 13 -15 32 -40 42 -55 10 -16 23 -34 30 -41 7 -7 25 -34 40 -60
15 -26 37 -60 49 -77 80 -108 226 -421 292 -625 99 -307 136 -547 136 -890 0
-238 -9 -334 -50 -545 -32 -165 -104 -411 -149 -510 -8 -16 -25 -57 -39 -90
-63 -153 -203 -393 -320 -550 -151 -202 -289 -343 -531 -544 -34 -28 -191
-138 -216 -151 -13 -7 -26 -16 -29 -20 -3 -4 -32 -22 -65 -40 -33 -18 -76 -43
-95 -55 -19 -12 -73 -39 -120 -60 -47 -20 -98 -43 -115 -51 -200 -88 -445
-157 -700 -196 -129 -20 -188 -23 -415 -23 -225 1 -288 4 -414 24 -270 41
-524 114 -723 207 -28 13 -57 24 -62 24 -6 0 -11 5 -11 10 0 6 -6 10 -13 10
-14 0 -138 58 -147 69 -3 3 -21 14 -40 24 -59 31 -95 52 -100 57 -3 3 -39 27
-80 55 -349 230 -673 579 -882 950 -103 182 -223 465 -257 604 -5 20 -19 72
-30 116 -40 148 -71 365 -78 528 l-6 157 -189 0 -188 0 0 -77 c1 -342 94 -788
233 -1118 91 -215 102 -240 108 -245 3 -3 16 -25 27 -50 24 -52 70 -133 116
-205 18 -27 45 -70 61 -95 32 -51 173 -237 219 -289 80 -91 162 -179 195 -210
20 -18 59 -54 86 -80 147 -138 324 -271 519 -391 79 -48 273 -149 326 -170 19
-8 37 -17 40 -20 5 -6 197 -80 270 -104 572 -185 1131 -215 1715 -90 290 62
661 203 875 333 19 11 44 25 56 30 55 25 364 243 469 332 50 43 300 292 340
339 81 96 117 143 183 235 39 55 76 105 82 112 5 7 10 15 10 17 0 3 16 30 35
60 193 302 355 718 421 1078 44 243 54 358 54 618 0 360 -43 650 -147 975 -65
202 -208 525 -279 630 -13 20 -33 53 -44 73 -11 20 -28 49 -38 63 -9 14 -30
45 -46 69 -36 55 -179 243 -220 290 -39 45 -301 305 -346 345 -69 60 -277 213
-370 272 -468 296 -960 456 -1518 496 -31 2 -46 -2 -52 -13z"/>
<path d="M3255 6792 c-94 -53 -260 -157 -263 -164 -2 -4 -8 -8 -13 -8 -9 0
-82 -52 -99 -70 -3 -3 -25 -21 -50 -40 -25 -19 -50 -39 -56 -45 -6 -5 -46 -41
-89 -80 -68 -61 -132 -129 -260 -276 -95 -109 -261 -359 -309 -467 -10 -23
-23 -42 -28 -42 -4 0 -8 -5 -8 -11 0 -5 -10 -31 -21 -57 -135 -296 -210 -558
-244 -852 -29 -254 -15 -635 32 -850 42 -197 106 -411 157 -525 59 -134 87
-191 128 -264 27 -46 48 -86 48 -88 0 -2 24 -40 54 -86 423 -648 1079 -1087
1826 -1221 272 -48 509 -58 763 -31 340 36 615 114 923 261 117 56 218 113
324 183 47 31 87 58 90 61 3 3 32 25 65 49 123 92 298 257 403 380 26 30 56
64 68 77 12 12 24 28 28 36 3 7 20 31 37 53 16 22 46 65 67 95 20 30 40 57 43
60 3 3 24 37 46 75 169 294 261 534 332 860 40 186 68 497 55 624 l-7 71 -76
-5 c-42 -3 -141 -8 -221 -11 l-145 -6 -2 -161 c-2 -187 -14 -293 -53 -467 -98
-434 -294 -791 -610 -1111 -122 -124 -189 -182 -273 -241 -339 -237 -635 -360
-1012 -420 -201 -32 -522 -32 -715 1 -175 29 -268 52 -415 104 -136 47 -347
151 -463 228 -40 27 -76 49 -81 49 -5 0 -11 7 -15 15 -3 8 -11 15 -18 15 -7 0
-33 18 -57 40 -25 22 -48 40 -52 40 -9 0 -249 237 -295 290 -112 132 -232 311
-291 435 -15 33 -31 62 -34 65 -6 5 -63 146 -98 240 -80 219 -122 477 -122
754 -1 329 41 549 164 872 60 156 213 425 307 538 174 208 279 312 445 438 66
50 123 98 127 108 11 30 14 500 3 500 -5 0 -23 -8 -40 -18z"/>
</g>
</svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}